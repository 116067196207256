<template>
    <v-snackbar
        class="fancy-snackbar px-4"
        :style="{ marginBottom: isUpper ? '180px' : '24px' }"
        v-bind="{ ...commonAttrs, ...$attrs }"
        v-on="$listeners"
        :value="value"
        @input="handleOpen"
    >
        {{ label }}
        <template v-if="isShowClose" v-slot:action="{ attrs }">
            <img
                width="24"
                height="24"
                class=""
                src="@/assets/images/ic24Delete.svg"
                alt=""
                v-bind="attrs"
                @click="handleOpen(false)"
            />
        </template>
        <template
            v-for="(_, scopedSlotName) in $scopedSlots"
            #[scopedSlotName]="slotData"
        >
            <slot :name="scopedSlotName" v-bind="slotData" />
        </template>
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-snackbar>
</template>

<script>
export default {
    name: 'FancySnackbar',
    inheritAttrs: false,
    emits: ['input'],
    props: {
        value: null,
        label: String,
        isShowClose: {
            type: Boolean,
            default: false,
        },
        isUpper: { type: Boolean, default: false },
    },
    data: () => ({}),
    computed: {
        commonAttrs() {
            return {
                text: true,
                timeout: 2000,
            };
        },
    },
    created() {},
    methods: {
        handleOpen(status) {
            this.$emit('input', status);
        },
    },
};
</script>

<style lang="scss">
.fancy-snackbar {
    &.v-snack {
        > .v-sheet.v-snack__wrapper {
            min-width: 100% !important;
            background: #fcf2eb;
            border-radius: 2px;
            &:before,
            &:after {
                content: none;
            }
            > .v-snack__content {
                padding: 16px 0 16px 16px;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: -0.3px;
                color: #ff4040;
            }
        }
    }
}
</style>
