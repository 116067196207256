<template>
    <div class="tools">
        <BackOnTop />
        <FancySnackbar v-model="showAlert" :label="errorMsg" isUpper />
        <div class="bg_white">
            <h2>營養計算神器</h2>
            <Tab
                :tabList="tabList"
                :currentTab="currentTab"
                @update="tabHandler"
            />
        </div>

        <v-container class="content">
            <h5>選擇神器</h5>

            <FancySelect
                v-model="currentCalcOption"
                class="sm_select mb-7"
                :items="currentCalcOptionList"
                :options="currentCalcOptionList"
                filled
                solo
            />
            <!-- 每日建議攝取量 -->
            <v-form ref="form" v-model="isFormValid" lazy-validation>
                <v-row class="mt-0" v-if="currentCalcOption === 'daily'">
                    <v-col cols="12" sm="12" class="py-0 mb-4">
                        <FancyInputNoBorder
                            v-model="form.weight"
                            placeholder="目前體重"
                            type="number"
                            name="weight"
                            persistent-hint
                            :rules="[() => !!form.weight || '請輸入目前體重']"
                            class="no_border"
                            filled
                            required
                    /></v-col>
                    <v-col cols="12" sm="12" class="py-0 mb-4">
                        <FancySelect
                            v-model="form.age"
                            class="no_border"
                            :items="currentSettings.ageOption"
                            :options="currentSettings.ageOption"
                            :label="currentSettings.ageLabel"
                            :rules="[() => !!form.age || '請輸入年紀']"
                            filled
                            solo
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        class="py-0 mb-4"
                        v-if="form && form.age.match(/[減肥增重]/) != null"
                    >
                        <FancyInputNoBorder
                            v-model="form.weight_goal"
                            placeholder="目標體重"
                            type="number"
                            name="weight"
                            persistent-hint
                            :rules="[
                                () => !!form.weight_goal || '請輸入目標體重',
                            ]"
                            class="no_border"
                            filled
                            required
                    /></v-col>
                </v-row>
                <v-row
                    class="mt-0"
                    v-if="currentCalcOption === 'feed' || 'water'"
                >
                    <v-col
                        cols="12"
                        sm="12"
                        class="py-0 mb-4"
                        v-for="el in currentSettings.inputList"
                        :key="el.placeholder"
                    >
                        <FancyInputNoBorder
                            v-model="form[el.formName]"
                            :placeholder="el.placeholder"
                            :type="el.type"
                            persistent-hint
                            :rules="[
                                () =>
                                    el.required
                                        ? !!form[el.formName] || '必填欄位'
                                        : true,
                            ]"
                            class="no_border"
                            :unit="el.unit"
                            :ps="el.ps"
                            filled
                            :required="el.required"
                        />
                        <font class="f_grey f12" v-if="el.ps">{{ el.ps }}</font>
                    </v-col>
                </v-row>
                <v-row>
                    <v-btn rounded color="primary" @click="validate">
                        開始計算
                    </v-btn>
                </v-row>
            </v-form>
            <!-- 營養成分 -->
            <!-- 飲食水分攝取量計算 -->
        </v-container>
        <div class="result" v-if="showResult">
            <img src="../../assets/images/img_clip.png" alt="" />
            <div class="detail">
                <h3>計算結果</h3>
                <div class="table">
                    <div
                        class="tr"
                        v-for="el in currentSettings.resultList"
                        :key="el.thTxt"
                    >
                        <div class="th">{{ el.thTxt }}</div>
                        <div class="td">
                            <strong :class="{ f_red: el.hint }">{{
                                el.tdTxt
                            }}</strong
                            >{{ el.unit }}
                        </div>
                        <div class="ps" v-show="!!el.ps">
                            {{ el.ps }}
                        </div>
                    </div>
                </div>
                <div class="extra" v-if="currentSettings.extraSuggest">
                    <div
                        class="tr"
                        v-for="el in currentSettings.extraSuggest"
                        :key="el.title"
                    >
                        <p class="f_black">【{{ el.title }}】 {{ el.fail }}</p>
                        <p class="f_red" v-html="el.text" />
                    </div>
                    <p class="f_grey">
                        *此數值僅供參考，實際攝取量請諮詢專業獸醫師建議
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import Tab from '@/components/Tab.vue';
import FancyInputNoBorder from '@/components/FancyInputNoBorder.vue';
import FancySelect from '@/components/FancySelect.vue';
import FancySnackbar from '@/components/FancySnackbar.vue';

export default {
    name: 'Calculator',
    components: {
        BackOnTop,
        Tab,
        FancyInputNoBorder,
        FancySelect,
        FancySnackbar,
    },
    data: () => ({
        showAlert: false,
        errorMsg: '營養成分輸入錯誤，成分總和不可大於100，請重新輸入',
        isFormValid: true,
        tabList: [
            {
                name: '狗汪營養計算神器',
                value: 'dog',
            },
            {
                name: '貓喵營養計算神器',
                value: 'cat',
            },
        ],
        settings: {
            dog: {
                calcOption: [
                    { text: '每日建議攝取量', value: 'daily' },
                    { text: '狗糧營養成分', value: 'feed' },
                    { text: '飲食水分攝取量計算', value: 'water' },
                ],
                daily: {
                    ageLabel: '狗汪類型',
                    ageOption: [
                        {
                            text: '斷奶~3個月大幼犬',
                            value: '斷奶~3個月大幼犬',
                            y1: 2,
                            y2: 3,
                        },
                        {
                            text: '4個月~未滿1歲幼犬',
                            value: '4個月~未滿1歲幼犬',
                            y1: 1.5,
                            y2: 2,
                        },
                        {
                            text: '未結紮1~4歲成犬',
                            value: '未結紮1~4歲成犬',
                            y1: 1.6,
                            y2: 1.8,
                        },
                        {
                            text: '已結紮1~4歲成犬',
                            value: '已結紮1~4歲成犬',
                            y1: 1.2,
                            y2: 1.4,
                        },
                        {
                            text: '5~10歲中年犬',
                            value: '5~10歲中年犬',
                            y1: 1.1,
                            y2: 1.4,
                        },
                        { text: '11歲以上高齡犬', y1: 1.1, y2: 1.6 },
                        { text: '要減肥的成犬', y1: 0.8, y2: 1 },
                        { text: '要增重的成犬', y1: 1.4, y2: 2 },
                        { text: '懷孕30天後母犬', y1: 1.6, y2: 2 },
                        { text: '哺乳犬', y1: 2, y2: 4 },
                        { text: '活動犬(工作犬)', y1: 2, y2: 5 },
                    ],
                    resultList: [
                        {
                            thTxt: '每日水分攝取建議量',
                            tdTxt: '400~600',
                            unit: '毫升',
                            hint: '',
                            ps: '',
                        },
                        {
                            thTxt: '每日熱量攝取建議量',
                            tdTxt: '300',
                            unit: '大卡',
                            hint: '',
                            ps: '',
                        },
                    ],
                },
                feed: {
                    inputList: [
                        {
                            type: 'number',
                            placeholder: '蛋白質',
                            unit: '%',
                            required: true,
                            formName: 'protein',
                        },
                        {
                            type: 'number',
                            placeholder: '脂肪',
                            unit: '%',
                            required: true,
                            formName: 'fat',
                        },
                        {
                            type: 'number',
                            placeholder: '粗纖維',
                            unit: '%',
                            required: true,
                            formName: 'fiber',
                        },
                        {
                            type: 'number',
                            placeholder: '水分',
                            unit: '%',
                            required: true,
                            ps: '*未填寫以8%計算',
                            formName: 'water',
                        },
                        {
                            type: 'number',
                            placeholder: '灰分',
                            unit: '%',
                            required: false,
                            ps: '*未填寫以9%計算',
                            formName: 'ash',
                        },
                    ],
                    resultList: [
                        {
                            thTxt: '熱量(大卡/100g)',
                            tdTxt: '36.50',
                            unit: '大卡',
                            hint: false,
                            ps: '',
                        },
                        {
                            thTxt: '蛋白質',
                            tdTxt: '9.59',
                            unit: '%',
                            hint: true,
                            ps: '建議量 > 18%',
                        },
                        {
                            thTxt: '脂肪',
                            tdTxt: '23.29',
                            unit: '%',
                            hint: true,
                            ps: '建議量 35% ~ 65%',
                        },
                        {
                            thTxt: '碳水化合物',
                            tdTxt: '67.12',
                            unit: '%',
                            hint: true,
                            ps: '建議量 < 45%',
                        },
                    ],
                    extraSuggest: [
                        {
                            title: '蛋白質',
                            fail: '< 18% ',
                            text: '蛋白質比例過低容易造成營養不良的問題',
                        },
                        {
                            title: '脂肪',
                            fail: '< 35%',
                            text: '脂肪可提供狗狗對必需脂肪酸的需求，過低可能會造成營養不良的問題',
                        },
                        {
                            title: '碳水化合物',
                            fail: '>= 46%',
                            text: '長期食用過多的碳水化合物，容易產生糖尿病的問題',
                        },
                        {
                            title: '脂肪',
                            fail: '> 65%',
                            text: '過多的脂肪可能造成肥胖或胰臟負擔',
                        },
                    ],
                },
                water: {
                    inputList: [
                        {
                            type: 'number',
                            placeholder: '乾飼料攝取量',
                            unit: 'g',
                            required: true,
                            ps: '*未食用請填0',
                            formName: 'feedRatio',
                        },
                        {
                            type: 'number',
                            placeholder: '乾飼料水份比',
                            unit: '%',
                            required: false,
                            ps: '*未填寫以8%計算',
                            formName: 'feedWater',
                        },
                        {
                            type: 'number',
                            placeholder: '罐頭攝取量',
                            unit: 'g',
                            required: true,
                            ps: '*未食用請填0',
                            formName: 'canRatio',
                        },
                        {
                            type: 'number',
                            placeholder: '罐頭水份比',
                            unit: '%',
                            required: false,
                            ps: '*未填寫以75%計算',
                            formName: 'canWater',
                        },
                    ],
                    resultList: [
                        {
                            thTxt: '每日飲食水分攝取量(ml)',
                            tdTxt: '65',
                            unit: '毫升',
                            hint: true,
                            ps: '此數值僅供參考，實際攝取量請諮詢專業獸醫師建議',
                        },
                        {
                            thTxt: '每日水份攝取量尚差',
                            tdTxt: '40',
                            unit: '毫升',
                            hint: true,
                            ps: '此數值僅供參考，實際攝取量請諮詢專業獸醫師建議',
                        },
                    ],
                },
            },
            cat: {
                calcOption: [
                    { text: '每日建議攝取量', value: 'daily' },
                    { text: '貓糧營養成分', value: 'feed' },
                    { text: '飲食水分攝取量計算', value: 'water' },
                ],
                daily: {
                    ageLabel: '貓喵類型',
                    ageOption: [
                        { text: '1歲以下幼貓', y1: 2, y2: 3 },
                        { text: '未結紮1-6歲成貓', y1: 1.2, y2: 1.4 },
                        { text: '已結紮1-6歲成貓', y1: 1, y2: 1.3 },
                        { text: '7-10歲中年貓', y1: 1, y2: 1.2 },
                        { text: '11歲以上高齡貓', y1: 1.1, y2: 1.6 },
                        { text: '要減肥的成貓', y1: 0.8, y2: 1 },
                        { text: '要增重的成貓', y1: 1.2, y2: 2 },
                        { text: '懷孕30天後母貓', y1: 2, y2: 3 },
                        { text: '哺乳貓', y1: 2, y2: 4 },
                    ],
                    resultList: [
                        {
                            thTxt: '每日水分攝取建議量',
                            tdTxt: '400~600',
                            unit: '毫升',
                            hint: '',
                            ps: '',
                        },
                        {
                            thTxt: '每日熱量攝取建議量',
                            tdTxt: '300',
                            unit: '大卡',
                            hint: '',
                            ps: '',
                        },
                    ],
                },
                feed: {
                    inputList: [
                        {
                            type: 'number',
                            placeholder: '蛋白質',
                            unit: '%',
                            required: true,
                            formName: 'protein',
                        },
                        {
                            type: 'number',
                            placeholder: '脂肪',
                            unit: '%',
                            required: true,
                            formName: 'fat',
                        },
                        {
                            type: 'number',
                            placeholder: '粗纖維',
                            unit: '%',
                            required: true,
                            formName: 'fiber',
                        },
                        {
                            type: 'number',
                            placeholder: '水分',
                            unit: '%',
                            required: true,
                            ps: '*未填寫以8%計算',
                            formName: 'water',
                        },
                        {
                            type: 'number',
                            placeholder: '灰分',
                            unit: '%',
                            required: false,
                            ps: '*未填寫以9%計算',
                            formName: 'ash',
                        },
                    ],
                    resultList: [
                        {
                            thTxt: '熱量(大卡/100g)',
                            tdTxt: '36.50',
                            unit: '大卡',
                            hint: false,
                            ps: '',
                        },
                        {
                            thTxt: '蛋白質',
                            tdTxt: '9.59',
                            unit: '%',
                            hint: true,
                            ps: '建議量 > 35%',
                        },
                        {
                            thTxt: '脂肪',
                            tdTxt: '23.29',
                            unit: '%',
                            hint: true,
                            ps: '建議量 30% ~ 55%',
                        },
                        {
                            thTxt: '碳水化合物',
                            tdTxt: '67.12',
                            unit: '%',
                            hint: true,
                            ps: '建議量 < 10%',
                        },
                    ],
                    extraSuggest: [
                        {
                            title: '蛋白質',
                            fail: '< 35% ',
                            text: '貓咪為肉食性動物，蛋白質比例過低容易造成營養不良的問題',
                        },
                        {
                            title: '脂肪',
                            fail: '< 30%',
                            text: '脂肪可提供貓咪對必需脂肪酸的需求，過低可能造成營養不良的問題<br >※乾飼料的碳水化合物ME%建議 < 25%',
                        },
                        {
                            title: '碳水化合物',
                            fail: '> 10%',
                            text: '貓的飲食並不需要過多的碳水化合物，長期食用過多的碳水化合物，容易產生糖尿病的問題',
                        },
                        {
                            title: '脂肪',
                            fail: '> 65%',
                            text: '過多的脂肪可能造成肥胖',
                        },
                    ],
                },
                water: {
                    inputList: [
                        {
                            type: 'number',
                            placeholder: '乾飼料攝取量',
                            unit: 'g',
                            required: true,
                            ps: '*未食用請填0',
                            formName: 'feedRatio',
                        },
                        {
                            type: 'number',
                            placeholder: '乾飼料水份比',
                            unit: '%',
                            required: false,
                            ps: '*未填寫以8%計算',
                            formName: 'feedWater',
                        },
                        {
                            type: 'number',
                            placeholder: '罐頭攝取量',
                            unit: 'g',
                            required: true,
                            ps: '*未食用請填0',
                            formName: 'canRatio',
                        },
                        {
                            type: 'number',
                            placeholder: '罐頭水份比',
                            unit: '%',
                            required: false,
                            ps: '*未填寫以75%計算',
                            formName: 'canWater',
                        },
                    ],
                    resultList: [],
                },
            },
        },
        showResult: false,
        currentTab: 'dog',
        currentCalcOption: 'daily',
        currentResult: [],
        water_lower_bound: 0,
        water_upper_bound: 0,
        form: {
            weight: '',
            weight_goal: '',
            age: '',
            protein: '',
            fat: '',
            fiber: '',
            water: '8',
            ash: '9',
            feedRatio: '0',
            feedWater: '8',
            canRatio: '0',
            canWater: '75',
        },
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        this.$nextTick(() => {
            // setTimeout(() => {
            this.$store.state.isLoading = false;
            // }, 3000);
        });
        this.settings.dog.daily.ageOption = this.settings.dog.daily.ageOption.map(function(obj){
            obj.value = obj.text;
            return obj;
        })
        this.settings.cat.daily.ageOption = this.settings.cat.daily.ageOption.map(function(obj){
            obj.value = obj.text;
            return obj;
        })
    },
    computed: {
        currentCalcOptionList() {
            return this.settings[this.currentTab].calcOption;
        },
        currentSettings() {
            return this.settings[this.currentTab][this.currentCalcOption];
        },
    },
    updated() {
        // console.log(this.currentCalcOption);
    },
    watch: {
        currentCalcOption() {
            this.showResult = false;
        },
    },
    methods: {
        tabHandler(val) {
            this.currentTab = val;
            this.currentCalcOption = 'daily';
            this.showResult = false;
            this.form = {
                weight: '',
                weight_goal: '',
                age: '',
                protein: '',
                fat: '',
                fiber: '',
                water: '8',
                ash: '9',
                feedRatio: '0',
                feedWater: '8',
                canRatio: '0',
                canWater: '75',
            };
        },
        dailyRecommend(type, x, X, y1, y2, age) {
            return new Promise(function (resolve) {
                // W=70 * XorZ ^0.75 * Y1  ~  70 * XorZ ^0.75 * Y2
                console.log(type, x, y1, y2);
                // Water
                var water_lower_bound = 0;
                var water_upper_bound = 0;
                if (type == 'dog') {
                    // Dog
                    water_lower_bound = x * 55;
                    water_upper_bound = x * 110;
                } else {
                    // cat
                    water_lower_bound = x * 40;
                    water_upper_bound = x * 60;
                }

                // Food
                // if(is_thin_fat)
                if (age.match(/[減肥增重]/) != null) x = X;
                x = x ** 0.75;
                var food_lower_bound = 70 * x * y1;
                var food_upper_bound = 70 * x * y2;

                resolve([
                    Math.round(water_lower_bound * 100) / 100,
                    Math.round(water_upper_bound * 100) / 100,
                    Math.round(food_lower_bound * 100) / 100,
                    Math.round(food_upper_bound * 100) / 100,
                ]);
            });
        },
        foodWaterCaculate(i, l, k, m) {
            var parent = this;
            return new Promise(function (resolve) {
                var s, g1, g2;
                // S=I*(L*1/100)+K*M*(1/100)
                s = i * (l * 0.01) + k * (m * 0.01);
                // G=Q-S
                if (parent.water_lower_bound == 0) {
                    g1 = 0;
                    g2 = 0;
                } else {
                    g1 = parent.water_lower_bound - s;
                    g2 = parent.water_upper_bound - s;
                }
                resolve([s, g1, g2]);
            });
        },
        foodComponent(a, b, c, d, e) {
            return new Promise(function (resolve) {
                var pk, fk, h, hk, t, p, f, H;
                // PK=A*3.5
                pk = a * 3.5;

                // FK=B*8.5
                fk = b * 8.5;
                // HK=H*3.5   |  H=100-(A+B+C+D+E)
                H =
                    100 -
                    (parseInt(a) +
                        parseInt(b) +
                        parseInt(c) +
                        parseInt(d) +
                        parseInt(e));
                hk = H * 3.5;
                // T=PK+FK+HK
                t = pk + fk + hk;
                // P=(PK/T)*100
                p = (pk / t) * 100;
                // F=(FK/T)*100
                f = (fk / t) * 100;
                // H=(HK/T)*100
                h = (hk / t) * 100;
                // U=(W/T)*100
                // u = (w/t)*100;
                // console.log("pk",pk,"fk",fk,"hk",hk,"t",t,"H",H);
                resolve([t, p, f, h]);
            });
        },
        validate() {
            var parent = this;
            if (this.$refs.form.validate()) {
                var currentCalcOption =
                    this.settings[this.currentTab][this.currentCalcOption];
                if (this.currentCalcOption == 'daily') {
                    var ageOptionList = currentCalcOption.ageOption;
                    for (let i in ageOptionList) {
                        if (ageOptionList[i].text == parent.form.age) {
                            // var is_thin_fat = false;
                            parent
                                .dailyRecommend(
                                    parent.currentTab,
                                    parent.form.weight,
                                    parent.form.weight_goal,
                                    ageOptionList[i].y1,
                                    ageOptionList[i].y2,
                                    parent.form.age
                                )
                                .then(function (rsp) {
                                    parent.water_lower_bound = rsp[0];
                                    parent.water_upper_bound = rsp[1];
                                    currentCalcOption.resultList[0].tdTxt =
                                        rsp[0] + '~' + rsp[1];
                                    currentCalcOption.resultList[1].tdTxt =
                                        rsp[2] + '~' + rsp[3];
                                    parent.showResult = true;
                                    setTimeout(function () {
                                        window.scrollTo(
                                            0,
                                            document.body.scrollHeight ||
                                                document.documentElement
                                                    .scrollHeight
                                        );
                                    }, 500);
                                });
                        }
                    }
                } else if (this.currentCalcOption == 'feed') {
                    if (
                        parseFloat(parent.form.protein) +
                            parseFloat(parent.form.fat) +
                            parseFloat(parent.form.fiber) +
                            parseFloat(parent.form.water) +
                            parseFloat(parent.form.ash) >
                        100
                    ) {
                        this.showAlert = true;
                    } else {
                        parent
                            .foodComponent(
                                parent.form.protein,
                                parent.form.fat,
                                parent.form.fiber,
                                parent.form.water,
                                parent.form.ash
                            )
                            .then(function (rsp) {
                                for (let i in rsp) {
                                    currentCalcOption.resultList[i].tdTxt =
                                        Math.round(rsp[i] * 100) / 100;
                                    if (i == rsp.length - 1) {
                                        parent.showResult = true;
                                        setTimeout(function () {
                                            window.scrollTo(
                                                0,
                                                document.body.scrollHeight ||
                                                    document.documentElement
                                                        .scrollHeight
                                            );
                                        }, 500);
                                    }
                                }
                            });
                    }
                } else if (this.currentCalcOption == 'water') {
                    parent
                        .foodWaterCaculate(
                            parent.form.feedRatio,
                            parent.form.feedWater,
                            parent.form.canRatio,
                            parent.form.canWater
                        )
                        .then(function (rsp) {
                            currentCalcOption.resultList = [];
                            console.log(rsp);
                            currentCalcOption.resultList.push({
                                thTxt: '每日飲食水分攝取量(ml)',
                                tdTxt: Math.round(rsp[0] * 100) / 100,
                                unit: '毫升',
                                hint: true,
                                ps: '此數值僅供參考，實際攝取量請諮詢專業獸醫師建議',
                            });
                            if (Math.floor(rsp[1], 2) != 0) {
                                currentCalcOption.resultList.push({
                                    thTxt: '每日水份攝取量尚差',
                                    tdTxt:
                                        Math.round(rsp[1] * 100) / 100 +
                                        '~' +
                                        Math.round(rsp[2] * 100) / 100,
                                    unit: '毫升',
                                    hint: true,
                                    ps: '此數值僅供參考，實際攝取量請諮詢專業獸醫師建議',
                                });
                            } else {
                                currentCalcOption.resultList.push({
                                    thTxt: '',
                                    tdTxt: '',
                                    unit: '',
                                    hint: true,
                                    ps: '請先使用「每日建議攝取量」及填寫毛孩體重、類型，再進行計算！',
                                });
                            }
                            parent.showResult = true;
                            setTimeout(function () {
                                window.scrollTo(
                                    0,
                                    document.body.scrollHeight ||
                                        document.documentElement.scrollHeight
                                );
                            }, 500);
                        });
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
input,
select {
    width: 100%;
    padding: 16px;
}
select {
    border: 1px solid $color_main_first;
}
input {
    background-color: #f9f6f4;
}
.tools {
    min-height: 100vh;
    background-color: #f7f5f4;
    h2 {
        color: $color_main_first;
        font-size: 26px;
        font-weight: bold;
        padding: 12px 16px;
        line-height: 1;
        text-align: left;
    }
    .content {
        width: calc(100% - 32px);
        margin: 16px auto;
        background-color: #fff;
        border-radius: 16px;
        padding: 30px 16px;
        h5 {
            color: $color_main_first;
            font-size: 16px;
            line-height: 19px;
            margin: 0 auto 8px;
        }
        .tr {
            margin-bottom: 24px;
        }
        .sm_select {
            line-height: 35px;
            height: 35px;
        }
    }
    .result {
        width: calc(100% - 32px);
        margin: 0 auto 16px;
        background-color: #cbb59c;
        border-radius: 16px;
        padding: 16px;
        position: relative;
        img {
            position: absolute;
            width: 140px;
            top: 16px;
            transform: translate(-50%, 0);
        }
        .detail {
            background-color: #fff;
            border-radius: 16px;
            padding: 65px 24px 16px;
            h3 {
                font-size: 26px;
                line-height: 24px;
                font-family: Roboto;
                font-weight: bold;
                color: $color_main_first;
                margin-bottom: 24px;
            }
            .table {
                color: $color_main_grey_dark;
                font-size: 14px;
                line-height: 24px;
                .tr {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-bottom: 12px;

                    .th {
                    }
                    .td {
                        // text-align: right;
                    }
                    .ps {
                        width: 100%;
                        flex-shrink: 0;
                        font-size: 10px;
                        line-height: 12px;
                        text-align: left;
                        color: $color_main_grey;
                        margin-top: 2px;
                    }
                    strong {
                        margin-right: 3px;
                        font-weight: normal;
                    }
                }
            }
            .extra {
                font-size: 10px;
                text-align: left;
                padding: 12px 0 24px;
                .tr {
                    margin-bottom: 6px;
                }
            }
        }
    }
    button {
        height: 50px;
        background-color: $color_main_first;
        color: #fff;
        border-radius: 25px;
        font-size: 16px;
        line-height: 24px;
        width: 136px;
        margin-left: auto;
        margin-right: auto;
        // margin-top: 24px;
    }
    font.f12 {
        font-size: 12px;
        text-align: left;
        display: block;
        padding-left: 16px;
    }
}
</style>
